import React from "react";
import Header from "./Header";
import Menu from "./Menu";

const ForgotPasswordRest = () => {
  return (
    <div>
      <Menu weight="login" />
      <div className="row m-5">
        <div className="col-md-4 offset-md-4">
          <h3>Reset your password</h3>
          <input
            type="text"
            className="form-control"
            placeholder="New password"
          />
          <input
            type="text"
            className="form-control my-3"
            placeholder="Confirm password"
          />
          <button className="btn btn-primary mt-3">Reset</button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordRest;
