
// export default Subheader;
import React from "react";
import "./css/SubheaderStyles.css"; // Make sure this does not conflict with Tailwind

function Subheader() {
  return (
    <section className="bg-white text-gray-800">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-4 pb-1 md:pt-6 md:pb-6">
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4">Discover PriorityScan</h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-xl text-gray-600 mb-8">
                A Smart Way to Navigate Health Choices in Ontario. Built on a foundation of 15 years of CT and MRI wait time data, updated monthly, PriorityScan offers Ontarians a clear, easy-to-understand guide to manage their diagnostic imaging needs.
                {/* ... Rest of your content */}
              </p>
              {/* Add buttons or other content here */}
            <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => window.scrollTo({top: document.getElementById('IntakeFormSmallContainer').offsetTop, behavior: 'smooth'})}
            >
                Get Started
            </button>
            </div>
          </div>
          {/* ... Other content */}
        </div>
      </div>
    </section>
  );
}

export default Subheader;
