import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../Button";
import Menu from "../Menu";
import "../css/BookPageStyles.css";
import "../css/SignUpPageStyles.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
const { LINK } = require("../../config.js");

// Add a standalone password validation function
function validatePassword(password) {
  // Define your criteria for the password
  return (
    password.length >= 8 &&
    /\d/.test(password) && // checks for digits
    /[A-Z]/.test(password) && // checks for uppercase
    /[a-z]/.test(password) && // checks for lowercase
    /[!@#$%^&*]/.test(password)
  ); // checks for special characters
}

function SignUpPage() {
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassowrd: "",
  });
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [buttonLoadding, setButtonLoadding] = useState(false);

  async function sendSignUpRequest() {
    const email = signupData.email;
    const password = signupData.password;
    const passwordRepeat = signupData.repeatPassowrd;
    const firstName = signupData.firstName;
    const lastName = signupData.lastName;

    // Check all fields are filled
    if (!email || !password || !passwordRepeat || !firstName || !lastName) {
      toast.warn("All fields are mandatory", {
        // toast configuration
      });
      return;
    }

    // Check if passwords match
    if (password !== passwordRepeat) {
      toast.warn("Passwords do not match.", {
        // toast configuration
      });
      return;
    }

    // Validate the password
    if (!validatePassword(password)) {
      toast.warn(
        "Password does not meet the required criteria. You must include 1 uppercase, 1 lowercase, 1 number, and 1 special character",
        {
          // toast configuration
        }
      );
      return;
    }

    let data = {
      email,
      password,
      firstName,
      lastName,
    };
    try {
      console.log(LINK);
      const proxyUrl = LINK || "http://16.16.201.50:81";
      const res = await fetch(`${proxyUrl}/api/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const answer = await res.text();

      if (res.status === 201) {
        navigate("/login");
        // toast.success(
        //   "Registered user successfully! Now you can login in your profile!",
        //   {
        //     position: "top-center",
        //     autoClose: 3500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //   }
        // );

        // Call function to send verification email
        // await sendVerificationEmail(data.email);
        // alert('Registered user successfully! Now login in your new profile!')
      } else {
        toast.warn(answer, {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // alert(answer);
        // TODO: CHANGE ALERT TO MORE BEAUTIFULL STYLE
        // console.log
      }
    } catch (error) {
      console.error("There was an error:", error);
    }
  }

  async function sendOTP() {
    setButtonLoadding(true);
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(signupData?.email);
      if (!signupData?.email?.length || !isValidEmail) {
        toast.warn("Invalid email", {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // const proxyUrl = "http://localhost:5002";
        const proxyUrl = LINK || "http://16.16.201.50:81";
        const ans = await fetch(`${proxyUrl}/api/users/send-otp`, {
          // Update this to the correct endpoint for sending emails
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: signupData.email }),
        });
        const resp = await ans.json();
        if (resp?.success) {
          toast.success("OTP sent successfully", {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setStep(1);
        } else {
          toast.error(resp?.error || "Something went wrong!", {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
    setButtonLoadding(false);
  }

  async function verifyOTP() {
    setButtonLoadding(true);
    try {
      if (otp.length !== 6) {
        toast.warn("Please enter otp", {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // const proxyUrl = "http://localhost:5002";
        const proxyUrl = LINK || "http://16.16.201.50:81";
        const ans = await fetch(`${proxyUrl}/api/users/verify-otp`, {
          // Update this to the correct endpoint for sending emails
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: signupData.email, otpCode: otp }),
        });
        const resp = await ans.json();
        if (resp?.success) {
          toast.success("Email Verified!", {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setStep(2);
        }
      }
    } catch (error) {
      console.error("Error with email verification", error);
    }
    setButtonLoadding(false);
  }
  return (
    <div>
      <Menu weight="login"></Menu>
      <div id="sign-up-main-container">
        <div id="first-string-sign-up-page">Welcome to our website!</div>
        <div id="second-string-sign-up-page">Join and create your account.</div>

        <div className="mt-[20px]">
          {step === 0 ? (
            <>
              <div className="text-left">Enter your email</div>
              <input
                className="form-control input-sign-up"
                type="email"
                placeholder="Email"
                value={signupData.email}
                onChange={(e) =>
                  setSignupData({ ...signupData, email: e.target.value })
                }
              />
              <p className="text-left text-[14px] mt-[4px] text-[#616161]">
                Verify email with otp
              </p>
              <div className="mt-[15px] mb-[5px]">
                <Button
                  id="sign-up-sign-up-button"
                  onClick={() => sendOTP()}
                  disabled={buttonLoadding}
                  name="Send otp"
                />
              </div>
              <div id="bottom-text-sign-up-page">
                Or{" "}
                <a href="/login">
                  <i class="fas fa-link">Login</i>
                </a>
              </div>
            </>
          ) : step === 1 ? (
            <>
              <div>Please enter otp to verify your email</div>
              <div className="mt-[15px] mb-[5px]">
                <div className="m-auto w-fit pb-[15px]">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    inputStyle={{
                      border: "1px solid #A7ACB1",
                      borderRadius: "4px",
                      width: "27px",
                      height: "36px",
                      fontSize: "12px",
                      color: "#202223",
                      fontWeight: "400",
                      marginRight: "10px",
                    }}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <Button
                  // id="sign-up-sign-up-button"
                  onClick={() => verifyOTP()}
                  disabled={buttonLoadding}
                  name="Verify email"
                />
              </div>
              <div id="bottom-text-sign-up-page">
                Or{" "}
                <a href="/login">
                  <i class="fas fa-link">Login</i>
                </a>
              </div>
            </>
          ) : step === 2 ? (
            <>
              <input
                className="form-control input-sign-up"
                type="text"
                placeholder="First Name"
                onChange={(e) =>
                  setSignupData({ ...signupData, firstName: e.target.value })
                }
              />
              <input
                className="form-control input-sign-up"
                type="text"
                placeholder="Last Name"
                onChange={(e) =>
                  setSignupData({ ...signupData, lastName: e.target.value })
                }
              />
              <input
                className="form-control input-sign-up"
                type="password"
                placeholder="Password"
                onChange={(e) =>
                  setSignupData({ ...signupData, password: e.target.value })
                }
              />
              <div>
                Rules: 8 char, 1 uppercase, 1 lowercase, 1 special character
              </div>
              <input
                className="form-control input-sign-up"
                type="password"
                placeholder="Repeat password"
                onChange={(e) =>
                  setSignupData({
                    ...signupData,
                    repeatPassowrd: e.target.value,
                  })
                }
              />
              <div className="mt-[20px] mb-[5px]">
                <Button
                  // id="sign-up-sign-up-button"
                  onClick={() => sendSignUpRequest()}
                  name="Sign Up"
                />
              </div>
              <div id="bottom-text-sign-up-page">
                
                Or{" "}
                <a href="/login">
                  <i class="fas fa-link">Login</i>
                </a>
              </div>
            </>
            
          ) : null}
        </div>
        
      </div>
      
    </div>
    // TODO: CHECK IS PASSWORD REPEATED RIGHT
  );
}

export { SignUpPage };
