import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, Switch } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword";
import ForgotPasswordRest from "./components/ForgotPasswordRest";
import isLoged from "./components/functions/IsLoged";
import BookPage from "./components/pages/BookPage";
import FAQPage from "./components/pages/FAQPage";
import LoginPage from "./components/pages/LoginPage";
import MainPage from "./components/pages/MainPage";
import ProfilePage from "./components/pages/ProfilePage";
import { SignUpPage } from "./components/pages/SignUpPage";
import VerifyEmail from "./components/pages/VerifyEmail";
import BookPaidInfo from "./components/pages/BookPaidInfo";
import BlogPage from "./components/pages/BlogPage";
import FullBlogPost from './components/FullBlogPost';
import { Provider } from "jotai";

function App() {
  const [isLogedVar, setIsLogedVar] = useState(false); // Initialize with a default value (false)

  useEffect(() => {
    const checkLogin = async () => {
      const logged = await isLoged();
      setIsLogedVar(logged);
    };

    checkLogin();
  }, []);

  return (
    <Provider>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage isLoged={isLogedVar} />} />
          <Route path="/book" element={<BookPage isLoged={isLogedVar} />} />
          <Route path="/faq" element={<FAQPage isLoged={isLogedVar} />} />
          <Route path="/blog" element={<BlogPage isLoged={isLogedVar} />} />
          <Route
            path="/profile"
            element={<ProfilePage isLoged={isLogedVar} />}
          />
          <Route path="/login" element={<LoginPage isLoged={isLogedVar} />} />
          <Route path="/signup" element={<SignUpPage isLoged={isLogedVar} />} />
          <Route
            path="/forgot-password"
            element={<ForgotPassword isLoged={isLogedVar} />}
          />
          <Route
            path="/forgot-password-reset"
            element={<ForgotPasswordRest isLoged={isLogedVar} />}
          />
          <Route
            path="/signup/verify-email"
            element={<VerifyEmail isLoged={isLogedVar} />}
          />
          <Route
            path="/next-steps"
            element={<BookPaidInfo isLoged={isLogedVar} />}
          />
          <Route 
            path="/blog" 
            element={<BlogPage isLoged={isLogedVar} />} />
          <Route 
            path="/blog/:slug" 
            element={<FullBlogPost isLoged={isLogedVar} />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
