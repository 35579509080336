import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Header from "../Header";
import Menu from "../Menu";
import '../css/BookPageStyles.css';
import "../css/SignUpPageStyles.css";
const {LINK} = require('../../config.js')


function VerifyEmail() {
    const location = useLocation();
    const [verificationStatus, setVerificationStatus] = useState('');
  
    useEffect(() => {
      // Extract token from URL
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
  
      // Send token to backend for verification
      if(token){
        fetch(`${LINK}/api/users/verify-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
          })
          .then(response => response.json())
          .then(data => {
            if (data.message === 'Email verified successfully') {
              setVerificationStatus('Email verified successfully');
            } else {
              setVerificationStatus('Email verification failed');
            }
          });
      }

    }, [location]);
  
    return (
        <div>
            <Header  />
            <Menu weight='login' />

            <div style={{ textAlign: 'center', padding: '20px'}}>
                { verificationStatus.length < 1 ?
                ( <h1>Email Verifying...</h1> ) : 
                (<>
                <h1>{verificationStatus}</h1>
                <a href="/login" className="btn btn-success">Login Now</a>
                </>)
                }
            </div>
      </div>
    );
  }

export default VerifyEmail;

