import React from "react";
import Menu from "./Menu";
import "../components/css/NotLoginBlockStyles.css";

function NotLoginBlock(props) {
  return (
    <div>
      <Menu isLoged={props.isLoged} weight={props.weight}></Menu>
      <div id="not-login-block-main-container">
        <div>You are not logged in!</div>
        <div id="pleaseLoginText">
          Please{" "}
          <a href="/login">
            <i class="fas fa-link">Login</i>
          </a>{" "}
          or{" "}
          <a href="/signup">
            <i class="fas fa-link">Sign up</i>
          </a>{" "}
          to access this feature
        </div>
      </div>
    </div>
  );
}

export default NotLoginBlock;
