import React from 'react';
import { useParams } from 'react-router-dom';
import priorityScanBlog from './data/priorityScanBlog';
import "./css/BlogPageStyles.css"; // Import the styles
import Menu from "./Menu";
import Blogheader from "./BlogHeader";

const FullBlogPost = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const post = priorityScanBlog.find(p => p.slug === slug); // Find the post by slug

  if (!post) {
    return <div>Post not found!</div>;
  }

  if (post) {
    document.title = post.title; // Set the document title to the post title
  }
  return (
    <div>
      <Menu weight="blog" />
      <Blogheader></Blogheader>
    <div className="blog-container">
      <h1 className="blog-title">{post.title}</h1>
      {/* Render your post content here using the blog-content class for styling */}
      <div className="blog-content" dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
    </div>
  );
};

export default FullBlogPost;
