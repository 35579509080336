
// export default Subheader;
import React from "react";
import "./css/BlogHeaderStyles.css"; // Make sure this does not conflict with Tailwind

function Subheader() {
  return (
    <section className="bg-white text-gray-800">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-4 pb-1 md:pt-6 md:pb-6">
          <div className="text-center pb-12 md:pb-16">
            <h1 id="top_title" className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4">PriorityScan</h1>
            <div className="max-w-5xl mx-auto">
              <p className="text-xl text-gray-600 mb-1">
              Navigating the complex world of healthcare can often feel overwhelming, especially when it comes to making informed decisions about diagnostic imaging like CT and MRI scans. That's where PriorityScan steps in—a beacon of clarity for Ontarians. Leveraging an extensive database built on 15 years of CT and MRI wait time data and refreshed monthly, PriorityScan provides an indispensable resource. It offers a transparent, straightforward guide designed to help residents of Ontario manage their diagnostic imaging needs efficiently. Whether you're a patient awaiting crucial scans or a healthcare professional seeking to optimize scheduling, PriorityScan illuminates the path towards swift, informed healthcare decisions. Welcome to a smarter way to navigate health choices in Ontario.
                {/* ... Rest of your content */}
              </p>
              {/* Add buttons or other content here */}
            {/* <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => window.scrollTo({top: document.getElementById('IntakeFormSmallContainer').offsetTop, behavior: 'smooth'})}
            >
                Get Started
            </button> */}
            </div>
          </div>
          {/* ... Other content */}
        </div>
      </div>
    </section>
  );
}

export default Subheader;
