import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import './css/BurgerMenuForMobileVersion.css'

const BurgerMenuForMobileVersion = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen){
      props.setBodyOverflow('auto');
    }else{
      props.setBodyOverflow('hidden');
    }
  };

  return (
    <div id='burger-menu-container-in-file'>
      <button id="burger-menu-button" onClick={toggleMenu}>
        ☰
      </button>
      <Menu right id='burger-menu-id' isOpen={isMenuOpen} onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)} noOverlay>
        <a id="home" className="burger-menu-item" href="/">Main page</a>
        <a id="book" className="burger-menu-item" href="/book">Book</a>
        <a id="faq" className="burger-menu-item" href="/faq">FAQ</a>
        <a id="blog" className="burger-menu-item" href="/blog">BLOG</a>
        <a id="profile" className="burger-menu-item" href="/profile">Profile</a>
        {props.isLoged ?
            <a id="login" className="burger-menu-item" onClick={() => {
              localStorage.setItem('accessToken', '')
              localStorage.setItem('googleUser', '')
              }} href="/login">Log out</a>
            :
            <a id="login" className="burger-menu-item" href="/login">Log in</a>}
      </Menu>
    </div>
  );
};

export default BurgerMenuForMobileVersion;