import React, { useState } from "react";
import Header from "./Header";
import Menu from "./Menu";

const ForgotPassword = () => {
  return (
    <div>
      <Menu weight="login" />
      <div className="row m-5">
        <div className="col-md-4 offset-md-4">
          <h3>Enter your email</h3>
          <input
            className="form-control input-sign-up"
            type="email"
            placeholder="Enter Email"
          />
          <button className="btn btn-primary mt-3">Submit</button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
