const { LINK } = require('../../config.js');

async function sendAccessToken(accessToken) {
  try {
    const proxyUrl = LINK || 'http://16.16.201.50:81';
    const response = await fetch(proxyUrl + '/api/users/isloged/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken: accessToken })
    });

    const answer = await response.json();
    return answer.wasVerified === true;
  } catch (error) {
    console.error('Error in sendAccessToken:', error);
    return false; // Or handle the error as appropriate
  }
}

async function isLoged() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return await sendAccessToken(accessToken);
  }
  return false;
}

export default isLoged;
