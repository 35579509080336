import React, { useState } from "react";
import Menu from "../Menu";
import Faq from "react-faq-component";
import "../css/FAQPageStyles.css";
import AnalyticsCharts from "../Analytics";
import PricingTables from "../PricingTable";
import Footer from "../Footer";

function FAQPage(props) {
  // State variable for the expandable list
  const [expanded, setExpanded] = useState(-1);

  document.title = "Priority Scan";

  const faqs = {
    title: "Frequently Asked Questions",
    rows: [
      {
        title: "What is PriorityScan?",
        content: `PriorityScan is an online platform that utilizes information collected by Ontario Health to help you find the shortest waiting times for CT and MRI scans in your area.`,
      },
      {
        title: "How does PriorityScan work?",
        content:
          "Simply input your postal code, the type of scan you need (CT or MRI), and how far you are willing to travel. PriorityScan will then provide you with a list of healthcare facilities with the shortest wait times within your specified range.",
      },
      {
        title: "What information is required to use PriorityScan?",
        content:
          "You need to provide your postal code, the type of scan you need (CT or MRI), and your travel radius in kilometers.",
      },
      {
        title: "How is my data used?",
        content:
          "We use your information, like where you live and how long you usually wait, to guess the wait times for CT and MRI scans near you. We also use this information to make our service better and to learn about general wait times in healthcare. Your privacy is very important to us, so we always keep your information private and safe.",
      },
      {
        title: "Is there a cost associated with using PriorityScan?",
        content:
          "As of now, PriorityScan is in its beta phase, and we are pleased to offer our services free of charge during this period. Our goal is to refine and optimize the platform to best meet the needs of our users. Please be advised that in the future, upon conclusion of the beta phase, there will be a fee associated with accessing the data provided by PriorityScan. We are committed to transparency and will communicate any changes to our pricing structure in a timely manner.",
      },
      {
        title:
          "How accurate is the waiting time information provided by PriorityScan?",
        content:
          "PriorityScan utilizes the latest data from Ontario Health to provide accurate and up-to-date waiting times for CT and MRI scans. Our database is typically updated on a monthly basis, aligning with the updates from Ontario Health. Furthermore, our predictions are backed by a robust dataset comprising 15 years of historical data, which enhances the reliability of the information we provide. Rest assured, we are committed to delivering precise and timely data to assist you in making informed decisions about your healthcare needs.",
      },
      {
        title: "Does PriorityScan cover all areas in Ontario?",
        content:
          "PriorityScan provides coverage for all publicly accessible MRI and CT scanners in Ontario, using data from healthcare facilities that report to Ontario Health. Please note our database is contingent upon the availability of data from each facility. If a facility does not contribute data to Ontario Health, it will not be included in our listings.",
      },
      {
        title: "Can PriorityScan help me schedule my scan?",
        content:
          "PriorityScan provides wait time information to assist you in making informed decisions. However, due to Ontario's healthcare regulations, we are unable to schedule scans directly. Please contact the medical facility to arrange your scan. It's also important for the healthcare professional who requests your scan to receive and review the results. This ensures they can effectively manage your follow-up care based on these findings.",
      },
      {
        title: "Is payment secure?",
        content:
          "PriorityScan uses Stripe to handle our financial transactions. More information can be found here: https://stripe.com/en-ca/privacy",
      },
    ],
  };

  return (
    <div className="App">
      <Menu isLoged={props.isLoged} weight="faq" />
      {/* <PricingTables /> */}
      <div id="faq-page-main-container" className="max-w-[1180px] mx-auto px-[20px] md:px-[30px] pt-[40px] pb-[20px] md:pt-[50px]">
        
        <Faq data={faqs} />
        <footer></footer>
        <AnalyticsCharts />
      </div>
    </div>
  );
}

export default FAQPage;
