// development
// const LINK = "http://localhost:5002";
// const WEBSITE_URL = "http://localhost:3001";

//Production
const LINK = "https://priorityscan.com";
const WEBSITE_URL = "www.priorityscan.com";

//Staging
// const LINK = "http://3.88.132.159";
// const WEBSITE_URL = "http://3.88.132.159";

module.exports = { LINK: LINK, WEBSITE_URL: WEBSITE_URL };
