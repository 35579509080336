import React from "react";
import "./css/ButtonStyles.css";

function Button(props) {
  if (props.width) {
    return (
      <button
        id={props.id}
        className="buttonBackgroundModule"
        style={{ width: props.width }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.name}
      </button>
    );
  } else {
    return (
      <button
        id={props.id}
        className="buttonBackgroundModule"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.name}
      </button>
    );
  }
}

export default Button;
