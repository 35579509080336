import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntakeFormInput from "./IntakeFormInput.js";
import "./css/IntakeForm.css";
import "./css/IntakeFormInput.css";
const { LINK } = require("../config.js");

function startLoader() {
  const loader = document.createElement("div");
  loader.id = "loaderBlock";
  loader.className = "loader custom-loader-style";
  document.body.append(loader);
  console.log("startLoader called");
  document.getElementById("IntakeFormButtonNew").disabled = true;
  // document.getElementById('loaderBlock').style.display = "block";
}

function stopLoader() {
  const loader = document.getElementById("loaderBlock");
  if (loader) {
    loader.remove();
  }
  console.log("stopLoader called");
  document.getElementById("IntakeFormButtonNew").disabled = false;
  document.getElementById("loaderBlock").style.display = "none";
}

function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}


function IntakeForm(props) {
  const apiURL =
    "https://api.ipgeolocation.io/ipgeo?apiKey=792845246df14a2a85893042a16ceb10";
  //do something else
  // toast.update(id, { render: "All is good", type: "success", isLoading: false });

  // Use state to manage form values
  const [formData, setFormData] = useState({
    livingPlace: "",
    appointmentType: "ct",
    hasAppoinmentDate: false,
    appointmentDate: getTodayDate(),
    maxDistance: "50", // Default value for maxDistance
  });

  const getUserLocation = async () => {
    const fullURL = apiURL;
    try {
      const response = await fetch(fullURL);
      const respJson = await response.json();
      if (respJson?.zipcode) {
        setFormData({ ...formData, livingPlace: respJson?.zipcode });
      } else {
        // Handle the case where the postal code is not found
        toast.error("Postal code not found. Please check your input.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
      // Handle other types of errors (e.g., network issues)
      toast.error("An error occurred while fetching location data.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  React.useEffect(() => {
    // Check if there is data in localStorage
    const storedData = JSON.parse(localStorage.getItem("USER_CURRENT_APPT"));
    // If there is data, set the form values
    if (storedData) {
      if (!storedData.hasAppoinmentDate) storedData.hasAppoinmentDate = false;
      setFormData(storedData);
    }
    if (!storedData?.livingPlace) getUserLocation();
  }, []);

  const setFormVisibility = props.setFormVisibility;
  const setResponseData = props.setResponseData;
  function formatLivingPlace() {
    let value = formData?.livingPlace?.toUpperCase();
    value = value.replace(/[^A-Z0-9]/g, "").slice(0, 6);
    setFormData({ ...formData, livingPlace: value });
    // Check if the input matches the "A1A2B2" format
    const regex = /^[A-Z]\d[A-Z]\d[A-Z]\d$/;
    if (!regex.test(value) && value.length === 6) {
      toast.error('Invalid format. Please use the "A1A2B2" format.', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
  function submitIntakeFormFunction() {
    console.log("Submitting form", formData);
    // Step 1: Validate the form
    if (!validateForm()) {
      return; // Stop the submission if validation fails
    }

    // Step 2: Get form values

    let currentDate = new Date();
    const oneYearLater = new Date();
    oneYearLater.setFullYear(currentDate.getFullYear() + 1);
    const livingPlace = formData?.livingPlace
      ?.toUpperCase()
      .replace(/[^A-Z0-9]/g, "")
      .slice(0, 6);
    const appointmentType = formData?.appointmentType;
    const appointmentDate = formData?.hasAppoinmentDate
      ? formData?.appointmentDate
      : oneYearLater.toISOString().split("T")[0];
    const maxDistance = formData?.maxDistance;
    const proxyUrl = LINK || "http://16.16.201.50:81";

    // Step 3: Start the loader
    startLoader();

    // Step 4: Save the current form data to localStorage
    localStorage.setItem(
      "USER_CURRENT_APPT",
      JSON.stringify({
        livingPlace,
        appointmentType,
        hasAppoinmentDate: formData.hasAppoinmentDate,
        appointmentDate,
        maxDistance,
      })
    );

    // Step 5: Make the API request
    const id = toast.loading("Please wait, we are searching our database for shorter waits.");
    fetch(`${proxyUrl}/api/hospitals/getclosest`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        livingPlace,
        appointmentType,
        appointmentDate,
        maxDistance,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        stopLoader();
        console.log(`data:`, data);
        toast.dismiss(id);

        // Step 6: Handle the response
        if (data["message"]) {
          toast(data["message"], {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }

        // Step 7: Update state and localStorage with the received data
        setResponseData(data);
        localStorage.setItem(
          "listOfClosestHospitals",
          JSON.stringify(data.listOfClosestHospitals)
        );
        setFormVisibility(false);
      });
  }

  function validateForm() {
    // formatLivingPlace();
    let currentDate = new Date();
    const oneYearLater = new Date();
    oneYearLater.setFullYear(currentDate.getFullYear() + 1);
    const livingPlace = formData?.livingPlace
      ?.toUpperCase()
      .replace(/[^A-Z0-9]/g, "")
      .slice(0, 6);
    const appointmentType = formData?.appointmentType;
    const appointmentDate = formData?.hasAppoinmentDate
      ? formData?.appointmentDate
      : oneYearLater;
    const maxDistance = formData?.maxDistance;
    const livingPlaceRegex = /^[A-Z]\d[A-Z]\d[A-Z]\d$/;

    let isValid = true;

    if (!livingPlace) {
      toast.error("Living place is required.", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      isValid = false;
    } else if (!livingPlaceRegex.test(livingPlace)) {
      toast.error(
        'Invalid format for living place. Please use the "A1A2B2" format.',
        {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      isValid = false;
    }

    if (!appointmentType) {
      toast.error("Appointment type is required.", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      isValid = false;
    }

    if (!appointmentDate) {
      toast.error("Appointment date is required.", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      isValid = false;
    }

    if (!maxDistance) {
      toast.error("Maximum distance is required.", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      isValid = false;
    }
    if (maxDistance < 50) {
      toast.error("Distance should be greater than or equal to 50", {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      isValid = false;
    }
    return isValid;
  }
  console.log("has apt date", formData);

  return (
    <div id="IntakeFormMainContainer">
      <div id="IntakeFormSmallContainer">
        <div
          id="loaderBlock"
          class="spinner-border text-dark"
          style={{
            marginTop: "30vh",
            position: "absolute",
            left: "50%",
            display: "none",
          }}
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <div id="IntakeFormHeader">Intake Form</div>
        <div className="max-w-[420px] px-[20px] m-auto pb-[15px]">
          <div className="profile-page-field-logo pb-1">Postal code</div>
          <input
            //   onLoad={() => {
            //     disableFields();
            //   }}
            id="first-name-input"
            type="text"
            className="form-control"
            placeholder="M5V3K1"
            value={formData.livingPlace}
            onChange={(e) => {
              setFormData({ ...formData, livingPlace: e.target.value });
            }}
          />
        </div>
        <div className="max-w-[420px] px-[20px] m-auto pb-[15px]">
          <div className="profile-page-field-logo pb-1">Scan type</div>
          <select
            className="form-control"
            required
            value={formData.appointmentType} // Set the value from state
            onChange={(e) =>
              setFormData({ ...formData, appointmentType: e.target.value })
            }          >
            <option value="" disabled selected hidden>
              CT/MRI
            </option>

            <option value={"ct"}>CT</option>
            <option value={"mr"}>MRI</option>
          </select>
        </div>
        <div className="max-w-[420px] px-[20px] m-auto pb-[15px]">
          <div className="flex gap-2">
            <input
              className="mt-[3px] md2:mt-[6px]"
              id="appoiment-booked"
              type="checkbox"
              checked={formData.hasAppoinmentDate}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  hasAppoinmentDate: !formData?.hasAppoinmentDate,
                });
              }}
            />
            <div className="profile-page-field-logo pb-1">
              Do you have any appointment booked?
            </div>
          </div>
        </div>
        {formData?.hasAppoinmentDate ? (
          <div className="max-w-[420px] px-[20px] m-auto pb-[15px]">
            <div className="profile-page-field-logo pb-1">Appointment</div>
            <input
              id="appointment-date"
              type="date"
              className="form-control"
              value={formData.appointmentDate} // Set the value from state
              onChange={(e) =>
                setFormData({ ...formData, appointmentDate: e.target.value })
              }
            />
          </div>
        ) : null}
        <div className="max-w-[420px] px-[20px] m-auto pb-[15px]">
          <div className="profile-page-field-logo pb-1">
            Your preferred travel distance in kilometers?
          </div>
          <input
            //   onLoad={() => {
            //     disableFields();
            //   }}
            id="form-distance"
            type="number"
            min={50}
            className="form-control"
            value={formData.maxDistance}
            onChange={(e) => {
              setFormData({ ...formData, maxDistance: e.target.value });
            }}
          />
        </div>

        {/* <div id="IntakeFormDescription">
          My postal code is
          <IntakeFormInput
            mobile="true"
            name="livingPlace"
            placeholder="M5V3K1"
            width="6ch"
            onBlur={(e) => {
              e.target.value = e.target.value.toUpperCase();
              formatLivingPlace(e);
            }}
            default={formData.livingPlace} // Set the value from state
          />
          and have a
          <select
            id="appointmentType"
            className="IntakeFormInput IntakeFormSelect"
            style={{ width: "5vw", minWidth: "max-content" }}
            required
            value={formData.appointmentType} // Set the value from state
            onChange={(e) =>
              setFormData({ ...formData, appointmentType: e.target.value })
            }
          >
            <option value="" disabled selected hidden>
              CT/MRI
            </option>

            <option value={"ct"}>CT</option>
            <option value={"mr"}>MRI</option>
          </select>
          appointment booked on
          <IntakeFormInput
            mobile="true"
            type="date"
            name="appointmentDate"
            required
            placeholder={getTodayDate()}
            default={formData.appointmentDate} // Set the value from state
            onChange={(e) =>
              setFormData({ ...formData, appointmentDate: e.target.value })
            }
          />
          .
          <br />I am willing to travel within{" "}
          <IntakeFormInput
            onlyNums="true"
            min="0"
            name="maxDistance"
            style={{ textDecoration: "none" }}
            default={formData.maxDistance}
          />{" "}
          KM’s to get it done sooner.
        </div> */}
        <div className="pt-[10px]">
          <button
            id="IntakeFormButtonNew"
            className="m-auto"
            onClick={submitIntakeFormFunction}
          >
            Find Shorter Wait Times
          </button>
        </div>
      </div>
    </div>
  );
}

export default IntakeForm;
