import React from 'react';
import { BarChart, Card } from '@tremor/react';

// Provided arrays
const mean_wait_times_2022 = [{'name': 'BLUEWATER-SARNIA GENERAL SITE', 'mean wait time': 67.0},
{'name': 'BRANTFORD GENERAL HOSPITAL (THE)', 'mean wait time': 158.0},
{'name': 'CAMBRIDGE MEMORIAL HOSPITAL', 'mean wait time': 99.0},
{'name': 'CHATHAM-KENT HEALTH ALLIANCE - CHATHAM', 'mean wait time': 76.0},
{'name': 'CHEO-OTTAWA SITE', 'mean wait time': 69.0},
{'name': 'CORNWALL COMMUNITY HOSPITAL', 'mean wait time': 17.0},
{'name': 'GNMI - AJAX', 'mean wait time': 9.0},
{'name': 'GNMI - MISSISSAUGA', 'mean wait time': 23.0},
{'name': 'GRAND RIVER - WATERLOO SITE', 'mean wait time': 155.0},
{'name': 'GREY BRUCE -OWEN SOUND', 'mean wait time': 89.0},
{'name': 'GUELPH GENERAL HOSPITAL', 'mean wait time': 104.0},
{'name': 'HALTON HEALTHCARE SERVICES CORP-MILTON', 'mean wait time': 61.0},
{'name': 'HALTON HEALTHCARE SERVICES CORP-OAKVILLE', 'mean wait time': 90.0},
{'name': 'HAMILTON HEALTH SCIENCES CORP-GENERAL', 'mean wait time': 195.0},
{'name': 'HAMILTON HEALTH SCIENCES CORP-JURAVINSKI', 'mean wait time': 177.0},
{'name': 'HAMILTON HEALTH SCIENCES CORP-MCMASTER', 'mean wait time': 118.0},
{'name': 'HAWKESBURY AND DISTRICT GENERAL HOSPITAL', 'mean wait time': 66.0},
{'name': 'HOPITAL MONTFORT', 'mean wait time': 43.0},
{'name': 'HOSPITAL FOR SICK CHILDREN  (THE)', 'mean wait time': 146.0},
{'name': 'HSN-RAMSEY LAKE HEALTH CENTRE (LAURENTIAN)',
 'mean wait time': 107.0},
{'name': 'HUMBER RIVER HOSPITAL - WILSON SITE', 'mean wait time': 33.0},
{'name': 'JOSEPH BRANT HOSPITAL', 'mean wait time': 104.0},
{'name': 'KINGSTON HEALTH SCIENCES CENTRE-KINGSTON GENERAL',
 'mean wait time': 96.0},
{'name': 'KINGSTON MRI', 'mean wait time': 74.0},
{'name': 'LAKERIDGE HEALTH - AJAX', 'mean wait time': 56.0},
{'name': 'LAKERIDGE HEALTH -OSHAWA SITE', 'mean wait time': 66.0},
{'name': 'LONDON - UNIVERSITY SITE', 'mean wait time': 259.0},
{'name': 'LONDON -VICTORIA WESTMI', 'mean wait time': 187.0},
{'name': 'MACKENZIE HEALTH-CORTELLUCCI VAUGHAN HOSPITAL',
 'mean wait time': 48.0},
{'name': 'MACKENZIE RICHMOND HILL HOSPITAL', 'mean wait time': 46.0},
{'name': 'MARKHAM STOUFFVILLE HOSPITAL', 'mean wait time': 47.0},
{'name': 'NIAGARA -GREATER NIAGARA', 'mean wait time': 148.0},
{'name': 'NIAGARA -ST CATHARINES GEN', 'mean wait time': 156.0},
{'name': 'NORTH BAY REGIONAL HEALTH CENTRE', 'mean wait time': 60.0},
{'name': 'NORTH YORK GENERAL HOSPITAL', 'mean wait time': 83.0},
{'name': 'NORTHUMBERLAND HILLS HOSPITAL', 'mean wait time': 25.0},
{'name': "ORILLIA SOLDIERS' MEMORIAL HOSPITAL", 'mean wait time': 44.0},
{'name': 'OTTAWA HOSPITAL-CIVIC SITE', 'mean wait time': 56.0},
{'name': 'OTTAWA HOSPITAL-GENERAL SITE', 'mean wait time': 48.0},
{'name': 'PEMBROKE REGIONAL HOSPITAL INC.', 'mean wait time': 90.0},
{'name': 'PETERBOROUGH REGIONAL HEALTH CENTRE', 'mean wait time': 74.0},
{'name': 'QUEENSWAY-CARLETON HOSPITAL', 'mean wait time': 46.0},
{'name': 'QUINTE HEALTHCARE -BELLEVILLE', 'mean wait time': 92.0},
{'name': 'ROSS MEMORIAL HOSPITAL', 'mean wait time': 53.0},
{'name': 'ROYAL VICTORIA REGIONAL HEALTH CENTRE', 'mean wait time': 149.0},
{'name': 'SAULT AREA -SAULT STE MARIE', 'mean wait time': 57.0},
{'name': 'SCARBOROUGH HEALTH NETWORK - BIRCHMOUNT', 'mean wait time': 71.0},
{'name': 'SCARBOROUGH HEALTH NETWORK - CENTENARY', 'mean wait time': 52.0},
{'name': 'SCARBOROUGH HEALTH NETWORK - SCAR. GEN', 'mean wait time': 68.0},
{'name': 'SINAI HEALTH SYSTEM - MOUNT SINAI HOSPITAL SITE',
 'mean wait time': 105.0},
{'name': 'SOUTHLAKE REGIONAL HEALTH CENTRE', 'mean wait time': 95.0},
{'name': "ST JOSEPH'S - LONDON", 'mean wait time': 124.0},
{'name': "ST JOSEPH'S HAMILTON-CHARLTON (HAMILTON)", 'mean wait time': 72.0},
{'name': "ST JOSEPH'S HAMILTON-WEST 5TH (HAMILTON MH)",
 'mean wait time': 86.0},
{'name': "ST JOSEPH'S HEALTH CENTRE TORONTO", 'mean wait time': 73.0},
{'name': "ST MICHAEL'S HOSPITAL", 'mean wait time': 139.0},
{'name': 'STRATFORD GENERAL HOSPITAL', 'mean wait time': 117.0},
{'name': 'SUNNYBROOK HEALTH SCIENCES CENTRE', 'mean wait time': 148.0},
{'name': 'THUNDER BAY REGIONAL HLTH SCIENCES CTR', 'mean wait time': 78.0},
{'name': 'TIMMINS & DISTRICT GENERAL HOSPITAL', 'mean wait time': 50.0},
{'name': 'TOR. EAST HLTH NTWRK-MICHAEL GARRON HOSPITAL',
 'mean wait time': 51.0},
{'name': 'TRILLIUM HEALTH PARTNERS-CREDIT VALLEY', 'mean wait time': 36.0},
{'name': 'TRILLIUM HEALTH PARTNERS-MISSISSAUGA', 'mean wait time': 53.0},
{'name': 'UHN - PRINCESS MARGARET HOSPITAL', 'mean wait time': 122.0},
{'name': 'UHN - TORONTO GENERAL HOSPITAL', 'mean wait time': 135.0},
{'name': 'UHN - TORONTO WESTERN HOSPITAL', 'mean wait time': 128.0},
{'name': 'UNIVERSITY OF OTTAWA HEART INSTITUTE', 'mean wait time': 133.0},
{'name': 'WILLIAM OSLER - BRAMPTON CIVIC HOSPITAL', 'mean wait time': 58.0},
{'name': 'WILLIAM OSLER -ETOBICOKE', 'mean wait time': 35.0},
{'name': 'WINDSOR -METROPOLITAN', 'mean wait time': 139.0},
{'name': 'WINDSOR -OUELLETTE CAMPUS SITE', 'mean wait time': 139.0},
{'name': "WOMEN'S COLLEGE HOSPITAL", 'mean wait time': 83.0},
{'name': 'WOODSTOCK GENERAL HOSPITAL', 'mean wait time': 120.0}];

const median_wait_times_2022 = [
  72.0, 176.0, 103.0, 75.0, 63.0, 82.0, 75.0, 40.0, 68.0, 82.0,
  77.0, 119.0, 110.0, 60.0, 63.0, 40.0, 40.0, 60.0, 50.0, 49.0,
  79.0, 95.0, 58.0, 56.0, 86.0, 66.0, 46.0, 68.0, 60.0, 89.0,
  50.0, 88.0, 101.0, 69.0, 66.0, 91.0, 53.0, 60.0, 77.0, 49.0,
  76.0, 81.0, 65.0, 88.0, 89.0, 48.0, 88.0, 49.0, 75.0, 84.0,
  74.0, 76.0, 89.0, 71.0, 59.0, 84.0, 80.0, 95.0, 105.0, 50.0,
  77.0, 76.0, 55.0, 88.0, 94.0, 98.0, 93.0
];

const percentile_90th_wait_times_2022 = [
  93.0, 246.0, 185.0, 112.0, 97.0, 129.0, 117.0, 54.0, 105.0, 128.0,
  120.0, 201.0, 191.0, 85.0, 100.0, 49.0, 49.0, 85.0, 73.0, 72.0,
  120.0, 146.0, 86.0, 76.0, 129.0, 100.0, 65.0, 101.0, 90.0, 138.0,
  68.0, 133.0, 157.0, 100.0, 97.0, 140.0, 76.0, 88.0, 117.0, 70.0,
  117.0, 120.0, 98.0, 135.0, 133.0, 70.0, 133.0, 72.0, 110.0, 126.0,
  107.0, 117.0, 136.0, 107.0, 87.0, 126.0, 124.0, 147.0, 157.0, 76.0,
  117.0, 117.0, 82.0, 133.0, 141.0, 147.0, 141.0
];

const formatChartData = (data, label) => data.map((item, index) => ({
  name: mean_wait_times_2022[index].name, // Assuming names are consistent across data sets
  [label]: item
}));

const dataFormatter = (number) => Intl.NumberFormat('us').format(number).toString();

export default function AnalyticsCharts() {
  console.log("Rendering AnalyticsCharts");
  
  const medianChartData = formatChartData(median_wait_times_2022, 'median wait time');
  const percentile90ChartData = formatChartData(percentile_90th_wait_times_2022, '90th percentile wait time');
 
  return (
    <Card>
      <h3 className="text-lg font-medium text-tremor-content-strong dark:text-black">
        Hospital Wait Times 2022
      </h3>
      <BarChart
        className="mt-6"
        data={mean_wait_times_2022}
        index="name"
        categories={['mean wait time']}
        colors={['blue']}
        valueFormatter={dataFormatter}
        yAxisWidth={48}
      />
      <BarChart
        className="mt-6"
        data={medianChartData}
        index="name"
        categories={['median wait time']}
        colors={['green']}
        valueFormatter={dataFormatter}
        yAxisWidth={48}
      />
      <BarChart
        className="mt-6"
        data={percentile90ChartData}
        index="name"
        categories={['90th percentile wait time']}
        colors={['red']}
        valueFormatter={dataFormatter}
        yAxisWidth={48}
      />
    </Card>
  );
}
