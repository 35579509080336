import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import Menu from "../Menu";
import "../css/BookPageStyles.css";
import "../css/SignUpPageStyles.css";
const { LINK } = require("../../config.js");

function BookPaidInfo() {
  return (
    <div id="BookPageBackgroundContainer">
      <Menu weight="login" />
      <div id="BookPageBackgroundContainer" className="pb-[50px]">
        <h1 id="bookingNotice">Next Steps!</h1>
        <div className="px-[20px] md:px-[30px] max-w-[1180px] m-auto pt-[30px]">
          <div id="userCurrentApptContainerNew">
            <div>
              <h2 className="text-[16px]">
                <b>
                  You've successfully utilized Priority Scan to find a quicker
                  appointment for your MRI or CT scan.{" "}
                </b>
              </h2>
            </div>
            <div>
              <h2 id="betaNotice" className="mt-[5px] md:mb-[30px] mb-[20px]">
                This is free at the moment, but we welcome your feedback on ways to improve our service!
              </h2>
              <ol className="md:pl-[20px]">
                <li>
                  <b>Print Your Referral Form:</b>
                  <div className="mb-[15px]">
                    Begin by printing out the referral form provided by Priority
                    Scan. This document is essential—it serves as an official
                    recommendation for a faster appointment at an alternative
                    scanning location.
                  </div>
                </li>
                <li>
                  <b>Schedule an Appointment with Your Doctor:</b>{" "}
                  <div className="mb-[15px]">
                    Arrange a follow-up visit with your referring physician.
                    This step is crucial as it involves a professional review
                    and endorsement of the new scan location you've chosen.
                  </div>
                </li>
                <li>
                  <b>Present Your Findings:</b>
                  <div className="mb-[15px]">
                    During your appointment, professionally introduce the
                    alternative scanning option discovered through Priority
                    Scan. Highlight the potential for reduced wait times and the
                    benefits it offers for your health journey.
                  </div>
                </li>
                <li>
                  <b>Provide the Referral Form:</b>{" "}
                  <div className="mb-[15px]">
                    Hand over the printed referral form to your doctor. This
                    form contains all the necessary details for them to
                    efficiently process the change in your scan location.
                  </div>
                </li>
                <li>
                  <b>Request the Referral Update:</b>{" "}
                  <div className="mb-[15px]">
                    Politely request your doctor to redirect your scan referral
                    to the new location. This is a pivotal moment where your
                    proactive approach aligns with professional healthcare
                    processes.
                  </div>
                </li>
                <li>
                  <b>Await Confirmation of Your New Appointment:</b>{" "}
                  <div>
                    Once your doctor has updated the referral, expect to receive
                    confirmation from the new scanning facility. Ensure that you
                    confirm the appointment details as soon as you receive them.
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookPaidInfo;
