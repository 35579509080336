import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../Button";
import Header from "../Header";
import Menu from "../Menu";
import NotLoginBlock from "../NotLoginBlock";
import "../css/BookPageStyles.css";
import isLoged from "../functions/IsLoged";
import CTAButton from "../CTAButton.js";
const { LINK } = require("../../config.js");
const proxyUrl = LINK || "http://16.16.201.50:81";

const blurTxt = "XXXXXXXXXXXXXXXXXXXXXXXXXX";

function BookPage(props) {
  document.title = "Priority Scan";
  if (props.isLoged) return BookPageAccessed(isLoged);
  else return <NotLoginBlock weight="book"></NotLoginBlock>;
}

function showInfoMessage() {
  toast.info(
    "Coming soon: You will soon be able to download the form for your desired location",
    {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
}

function calculateTimeSaved(originalDate, newDate) {
  const originalDateObject = new Date(originalDate);
  const newDateObject = new Date(newDate);
  const timeSaved = originalDateObject - newDateObject; // This will be in milliseconds
  return Math.ceil(timeSaved / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
}

function BookPageAccessed(isLoged) {
  const navigate = useNavigate();
  const location = useLocation();
  // State variable for whether the user has paid
  const [hasPaid, setHasPaid] = useState(false); // Set this to true when the user pays

  const [clientSecret, setClientSecret] = useState("");

  // State variable to control the display of the payment form
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  // Function to handle the pay button click
  const handlePayClick = () => {
    window.location.href = "https://buy.stripe.com/4gw4ja4fj41F2He147"; //$0.00 at this link  https://buy.stripe.com/4gw4ja4fj41F2He147
  };
// 4.99 https://buy.stripe.com/aEU2b2dPT9lZepWfZ0
// 17.99 https://buy.stripe.com/7sI9Du7rvgOr1Da4gh
  const verifySessionWithServer = (sessionId) => {
    const listOfCloseHospitals = JSON.parse(
      localStorage.getItem("listOfClosestHospitals")
    );
    const usersCurrentApt = JSON.parse(
      localStorage.getItem("USER_CURRENT_APPT")
    );
    const userEmail = localStorage.getItem("userEmail");
    fetch(`${proxyUrl}/api/verify-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripeSessionId: sessionId,
        usersCurrentApt,
        listOfCloseHospitals,
        userEmail,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Server response:", data); // Add this line
        if (data.paymentVerified) {
          setHasPaid(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handlePaymentSuccess = () => {
    const expirationTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes from now
    sessionStorage.setItem("hasPaid", "true");
    sessionStorage.setItem("paymentExpiration", expirationTime);
  };

  // THIS IS MY ATTEMPT AT PAYMENT VERIFICATION
  const handleSessionVerification = async (sessionId) => {
    try {
      console.log("Verifying session:", sessionId);
      const response = await fetchSessionVerification(sessionId);
      if (response.paymentVerified) {
        setHasPaid(true);
        emailBookingInfoToUser();
        // Additional UI updates or actions on success
      } else {
        // Handle unsuccessful verification
      }
    } catch (error) {
      console.error("Verification error:", error);
      // Update UI to show error state
    }
  };

  const fetchSessionVerification = async (sessionId) => {
    const response = await fetch(`${proxyUrl}/api/verify-session`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId,
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  };

  const emailBookingInfoToUser = () => {
    var closestHospitals = JSON.parse(
      localStorage.getItem("listOfClosestHospitals")
    );
    var USER_CURRENT_APPT = JSON.parse(
      localStorage.getItem("USER_CURRENT_APPT")
    );
    var userEmail = localStorage.getItem("userEmail");

    fetch(`${proxyUrl}/api/email-bookinginfo-to-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        closestHospitals: closestHospitals,
        appointmentDate: USER_CURRENT_APPT.appointmentDate,
        userEmail: userEmail,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log("Booking info send successfully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const downloadForm = (hospital) => {
    var USER_CURRENT_APPT = JSON.parse(
      localStorage.getItem("USER_CURRENT_APPT")
    );
     const appointmentType =
      USER_CURRENT_APPT.appointmentType &&
      USER_CURRENT_APPT.appointmentType === "ct"
        ? "CT"
        : "MRI";

    const hospitalName =
      hospital
        .replace("(THE)", "")
        .replace("SITE", "")
        .replace(/[^a-z]/gi, "")
        .toLowerCase() +
      "_" +
      appointmentType +
      ".pdf";

    console.log(hospitalName);

    const link = document.createElement("a");
    link.download = hospitalName;
    link.href = "/forms/" + hospitalName;
    link.click();
  };
  const gotoNextSteps = () => {
    navigate("/next-steps");
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");
    if (sessionId) {
      verifySessionWithServer(sessionId);
    }
  }, [location]);

  useEffect(() => {
    console.log("Has Paid State Updated:", hasPaid); // Add this line to verify the state update
    if (hasPaid) {
      emailBookingInfoToUser();
    }
  }, [hasPaid]); // Add hasPaid as a dependency

  var USER_CURRENT_APPT = JSON.parse(localStorage.getItem("USER_CURRENT_APPT"));
  var USER_NEW_APPT = JSON.parse(localStorage.getItem("NEWLY_FOUND_APPT"));

  if (USER_NEW_APPT) {
    const targetDate = USER_NEW_APPT.appointmentDate;
    const targetDateObject = new Date(targetDate);
    const currentDate = new Date();
    const timeDifference = targetDateObject - currentDate;
    USER_NEW_APPT.waitDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }

  var closestHospitals = JSON.parse(
    localStorage.getItem("listOfClosestHospitals")
  );
  // console.log(closestHospitals);

  // set up to save the total time saved data
  const timeSaved =
    USER_NEW_APPT && USER_CURRENT_APPT
      ? calculateTimeSaved(
          USER_CURRENT_APPT.appointmentDate,
          USER_NEW_APPT.appointmentDate
        )
      : 0;

  useEffect(() => {
    if (closestHospitals == undefined || closestHospitals == null) {
      navigate("/");
    }
  }, []);

  const closestHospitalsObjects = [];
  if (closestHospitals) {
    for (var i = 1; i < closestHospitals.length; i++) {}
    console.log("Rendering BookPageAccessed, hasPaid:", hasPaid);
  }

  return (
    <div className="App">
      <Menu isLoged={isLoged} weight="book" />
      <div id="BookPageBackgroundContainer" className="pb-[10px]">
        <div className="container">
          <div className="row">
            <div className="col-md-12 px-5">
              <div id="BookPagePurchaseButton">
                {hasPaid ? (
                  <Button
                    className="buttonBackgroundModule"
                    onClick={gotoNextSteps}
                    name="Next Steps"
                  >
                    Next Steps
                  </Button>
                ) : (
                  <Button
                    className="buttonBackgroundModule"
                    onClick={handlePayClick}
                    name="Unlock Instant Access to Wait Times"
                  />
                                      
                )}
              </div>
              {/* <CTAButton /> */}
            </div>
          </div>
        </div>
        {/* <StepIndicator /> */}
        {/* <div id="BookPageBackgroundContainer">
          <h1 id="bookingNotice" className="text-[24px]">
            Next Steps!
          </h1>
          <div className="px-[20px] md:px-[30px] max-w-[1180px] m-auto pt-[30px]">
            <div id="userCurrentApptContainerNew">
              <div>
                <h2 className="md:text-[18px]">
                  <b>
                    You've successfully utilized Priority Scan to find a quicker
                    appointment for your MRI or CT scan.{" "}
                  </b>
                </h2>
              </div>
              <div>
                <h2
                  id="betaNotice"
                  className="mt-[5px] md:mb-[30px] mb-[20px] md:text-[18px]"
                >
                  Please make sure to use the code BETA100 at checkout to avoid
                  paying fees during the public beta
                </h2>
                <ol className="md:pl-[20px] md:text-[18px]">
                  <li>
                    <b>Print Your Referral Form:</b>
                    <div className="mb-[15px]">
                      Begin by printing out the referral form provided by
                      Priority Scan. This document is essential—it serves as an
                      official recommendation for a faster appointment at an
                      alternative scanning location.
                    </div>
                  </li>
                  <li>
                    <b>Schedule an Appointment with Your Doctor:</b>{" "}
                    <div className="mb-[15px]">
                      Arrange a follow-up visit with your referring physician.
                      This step is crucial as it involves a professional review
                      and endorsement of the new scan location you've chosen.
                    </div>
                  </li>
                  <li>
                    <b>Present Your Findings:</b>
                    <div className="mb-[15px]">
                      During your appointment, professionally introduce the
                      alternative scanning option discovered through Priority
                      Scan. Highlight the potential for reduced wait times and
                      the benefits it offers for your health journey.
                    </div>
                  </li>
                  <li>
                    <b>Provide the Referral Form:</b>{" "}
                    <div className="mb-[15px]">
                      Hand over the printed referral form to your doctor. This
                      form contains all the necessary details for them to
                      efficiently process the change in your scan location.
                    </div>
                  </li>
                  <li>
                    <b>Request the Referral Update:</b>{" "}
                    <div className="mb-[15px]">
                      Politely request your doctor to redirect your scan
                      referral to the new location. This is a pivotal moment
                      where your proactive approach aligns with professional
                      healthcare processes.
                    </div>
                  </li>
                  <li>
                    <b>Await Confirmation of Your New Appointment:</b>{" "}
                    <div>
                      Once your doctor has updated the referral, expect to
                      receive confirmation from the new scanning facility.
                      Ensure that you confirm the appointment details as soon as
                      you receive them.
                    </div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div> */}

        <div id="bookingNotice" className="text-[24px]">
          Average Wait Times Found
        </div>
        <div id="BookPageForms">
          <div className="px-[20px] md:px-[30px] max-w-[1180px] m-auto">
            {USER_CURRENT_APPT ? (
              <div
                id="userCurrentApptContainerNew"
                className="mt-[20px] flex gap-x-9 flex-wrap"
              >
                <div id="userCurrentApptHeader">Current appointment</div>
                <div>
                  <div>
                    <b>TYPE:</b>{" "}
                    {USER_CURRENT_APPT
                      ? USER_CURRENT_APPT.appointmentType.toUpperCase()
                      : ""}
                  </div>
                  <div>
                    <b>DATE:</b>{" "}
                    {USER_CURRENT_APPT ? USER_CURRENT_APPT.appointmentDate : ""}
                  </div>
                  <div>
                    <b>LOCATION:</b>{" "}
                    {USER_CURRENT_APPT ? USER_CURRENT_APPT.livingPlace : ""}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {closestHospitals ? (
            <div className="mt-[30px]">
              {closestHospitals.map((hospital, index) => (
                <div className="px-[20px] md:px-[30px] max-w-[1180px] m-auto pb-[20px]">
                  <div id="newlyFoundApptContainer">
                    <div id="newlyFoundApptHeader">
                      Average wait time#{index + 1}
                    </div>
                    <div id="newlyFoundApptDescription">
                      <div id="newlyFoundDetails">
                        <div>
                          <b>Date:</b>{" "}
                          {
                            new Date(
                              new Date().setDate(
                                new Date().getDate() + hospital.medianWait
                              )
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                        </div>
                        <div style={hasPaid ? {} : { filter: "blur(10px)" }}>
                          {hasPaid ? (
                            <span>
                              <b>Hospital:</b> {hospital.Site}
                            </span>
                          ) : (
                            blurTxt
                          )}
                        </div>
                        <div>
                          <b>Earlier by this many days:</b>{" "}
                          {(
                            (new Date(USER_CURRENT_APPT.appointmentDate) -
                              new Date(
                                new Date().setDate(
                                  new Date().getDate() + hospital.medianWait
                                )
                              )) /
                            (1000 * 60 * 60 * 24)
                          ).toFixed(0)}
                        </div>
                        <div>
                          <b>Wait days:</b> {hospital.medianWait}
                        </div>
                        <div style={hasPaid ? {} : { filter: "blur(10px)" }}>
                          {hasPaid ? (
                            <span>
                              <b>Distance:</b> {hospital.distance.toFixed(1)} KM
                            </span>
                          ) : (
                            blurTxt
                          )}
                        </div>
                      </div>
                      {hasPaid ? (
                        <div className="bg-[#efefef] w-fit mt-[5px] rounded">
                          <button
                            className="btn btn-default"
                            onClick={() => downloadForm(hospital.Site)}
                          >
                            Download Form
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          {closestHospitals ? (
            <div>{closestHospitalsObjects}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>

      <div className="px-[20px] md:px-[30px] max-w-[1180px] m-auto py-[30px]">
        Disclaimer: This website is independently operated and has no
        affiliation with any hospitals or imaging departments. It is designed to
        assist users in identifying potential locations that may have shorter
        wait times for medical imaging services. Please note that all
        appointment times displayed are estimates and are based on the median
        wait times for CT and MRI at each location, as per data from Ontario
        Health. These times are subject to change and should be used as a
        guideline only. This website is not responsible for submitting or
        managing requests for appointments. It does not facilitate the
        completion or submission of any forms required by healthcare facilities.
        Users seeking to book an imaging appointment at any specified center
        must do so through their doctor's office. We strongly recommend
        verifying the current wait times and appointment details with the
        respective facility.
      </div>
    </div>
  );
}

function App() {}
export default BookPage;
