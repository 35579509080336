import React, { useState } from "react";
import "./css/HeaderStyles.css";
import isLoged from "./functions/IsLoged";

function Header() {
  const [loggedIn, setLoggedIn] = useState(false);
  isLoged().then((res) => {
    // console.log(res);
    setLoggedIn(res);
  });
  return (
    <div>
      <div id="alert-main-container-in-header"></div>
      <div id="mainContainer">www.priorityscan.com</div>
      {/* <div id="google_translate_element"></div> */}
      <div id="loggedInMsg">
        {loggedIn ? `Logged in as : ${localStorage.getItem("userEmail")}` : ""}{" "}
      </div>
    </div>
  );
}

export default Header;
