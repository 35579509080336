import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./css/TimeSaved.css";
import { useSpring, animated } from "@react-spring/web";
import { LINK } from "../config";

var totalItems = 0;
function calculateTotalTimeSaved() {
  var USER_CURRENT_APPT = JSON.parse(localStorage.getItem("USER_CURRENT_APPT"));
  var USER_NEW_APPT = JSON.parse(localStorage.getItem("NEWLY_FOUND_APPT"));
  var closestHospitals = JSON.parse(
    localStorage.getItem("listOfClosestHospitals")
  );
  var total = 0;
  if (closestHospitals) {
    for (var i = 1; i < closestHospitals.length; i++) {
      total +=
        (new Date(USER_CURRENT_APPT.appointmentDate) -
          new Date(
            new Date().setDate(
              new Date().getDate() + closestHospitals[i].medianWait
            )
          )) /
        (1000 * 60 * 60 * 24);
      totalItems++;
    }
  }
  return parseInt(total);
}

function getFormatedStringFromDays(numberOfDays) {
  var years = Math.floor(numberOfDays / 365);
  var months = Math.floor((numberOfDays % 365) / 30);
  var days = Math.floor((numberOfDays % 365) % 30);

  return `${years.toString().padStart(2, "0")} Years : ${months
    .toString()
    .padStart(2, "0")} Months : ${days.toString().padStart(2, "0")} Days`;
}

const TimeSaved = () => {
  const [totalTime, setTotalTime] = useState("00 Years : 00 Months : 00 Days");
  const [totalTimeNew, setTotalTimeNew] = useState({
    years: 0,
    months: 0,
    days: 0,
  });
  const springTime = useSpring(totalTimeNew);
  const [averageTime, setAverageTime] = useState(
    "00 Years : 00 Months : 00 Days"
  );

  const getTotalTimeSaved = async () => {
    const proxyUrl = LINK || "http://16.16.201.50:81";
    const resp = await fetch(`${proxyUrl}/api/totalTimeSaved`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((resp) => resp.json())
      .then((data) => data)
      .catch((e) => {
        console.log("e", e);
      });
    if (resp?.success) {
      const totalDays = resp?.days;
      if (totalDays) {
        const years = Math.floor(totalDays / 365);
        const months = Math.floor((totalDays % 365) / 30);
        const days = Math.floor((totalDays % 365) % 30);
        setTotalTimeNew({
          ...totalTimeNew,
          years,
          months,
          days,
        });
        setTotalTime(getFormatedStringFromDays(totalDays));
        // setAverageTime(getFormatedStringFromDays(avgDays));
      }
    }
  };
  useEffect(() => {
    getTotalTimeSaved();
  }, []);

  return (
    <>
      <div className="container">
        <div className="px-[20px] md:px-[30px] py-[20px] md:py-[50px] max-w-[1180px] m-auto">
          <div className="row timesaveContainerBox">
            <div className="col-md-12" id="timesaveContainer">
              <h2 className="mb-0">
                Total Time Saved
                <div className="text-[#449BE2] mt-[5px]">
                  <animated.span>
                    {springTime.years.to((x) => x.toFixed(0))}
                  </animated.span>{" "}
                  Years{" "}
                  <animated.span>
                    {springTime.months.to((x) => x.toFixed(0))}
                  </animated.span>{" "}
                  Months{" "}
                  <animated.span>
                    {springTime.days.to((x) => x.toFixed(0))}
                  </animated.span>{" "}
                  Days
                </div>
              </h2>
              {/* <h2>Average Time Saved : {averageTime}</h2> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TimeSaved;
