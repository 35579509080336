import React, { useState } from "react";
import Button from "../components/Button";
import "../components/css/MainPageResponceBlock.css";
const isLoged = require("./functions/IsLoged");
const blurTxt = "XXXXXXXXXXXXXXXXXXXXXXXXXX";

function MainPageResponceBlock(props) {
  // State variable for whether the user has paid
  const [hasPaid, setHasPaid] = useState(false); // Set this to true when the user pays

  localStorage.setItem("NEWLY_FOUND_APPT", JSON.stringify(props.data));
  return (
    <div id="MainPageResponceBlock">
      <div id="availability">Estimated Availability</div>
      <div id="description">
        Estimated earliest available{" "}
        <span id="appointmentType">
          {props.data.appointmentType || "props.appointmentType"}
        </span>{" "}
        on{" "}
        <span id="appointmentDate">
          {props.data.appointmentDate || "props.appointmentDate"}
        </span>{" "}
        at
        <span
          id="appointmentPlace"
          style={hasPaid ? {} : { filter: "blur(10px)" }}
        >
          {hasPaid
            ? props.data.appointmentPlace || "props.appointmentPlace"
            : blurTxt}
        </span>
        .<br></br>
        <br></br>
        {/* <span id='appointmentDateSoonerCount'>{props.data.appointmentDateSoonerCount}</span> days sooner than your previously scheduled date. */}
      </div>
      <div id="clickBelowText">Click below to unlock the data.</div>
      {props.isLoged ? (
        <div
          style={{
            display: "flex",
            margin: "auto",
            width: "60%",
            minWidth: "max-content",
            justifyContent: "space-around",
            paddingBottom: "7vh",
          }}
        >
          <Button
            mobile="true"
            id="main-page-responce-block-book-button"
            name="Unlock Wait Times"
            onClick={() => {
              window.location.assign("/book");
            }}
          ></Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            margin: "auto",
            width: "60%",
            minWidth: "max-content",
            justifyContent: "space-around",
            paddingBottom: "7vh",
          }}
        >
          <Button
            mobile="true"
            id="main-page-responce-block-login-button"
            name="Login"
            onClick={() => {
              window.location.assign("/login");
            }}
          ></Button>
          <Button
            mobile="true"
            id="main-page-responce-block-sign-up-button"
            name="Register"
            onClick={() => {
              window.location.assign("/signup");
            }}
          ></Button>
        </div>
      )}
      {/* <Button name='Google' width='10vw'></Button> */}
      <div id="bookSnswerBackground"></div>
    </div>
  );
}

export default MainPageResponceBlock;
