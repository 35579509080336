import React, { useState } from "react";
import IntakeForm from "../IntakeForm";
import MainBackground from "../MainBackground";
import Subheader from "../Subheader";
import MainPageResponceBlock from "../MainPageResponceBlock";
import Menu from "../Menu";
import TimeSaved from "../TimeSaved";
import AnalyticsCharts from "../Analytics"; 

function MainPage(props) {
  document.title = "Priority Scan";
  const [isFormVisible, setFormVisibility] = useState(true);
  const [responseData, setResponseData] = useState("");
  return (
    <div className="App">
      <Menu isLoged={props.isLoged}></Menu>
      <TimeSaved />
      {responseData && (
        <MainPageResponceBlock isLoged={props.isLoged} data={responseData} />
      )}
      {isFormVisible && (
        <div id="formAndAnswerContainer">
          <Subheader></Subheader>
          <IntakeForm
            setFormVisibility={setFormVisibility}
            setResponseData={setResponseData}
          />
          <MainBackground />
        </div>
      )}
      
    </div>
  );
}

export default MainPage;
