
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BurgerMenuForMobileVersion from "./BurgerMenuForMobileVersion.js";
// Ensure that MenuStyles.css does not conflict with Tailwind CSS
import "./css/MenuStyles.css";
import logo from "./img/logo_beta.jpg";

function Menu(props) {

  // Function to lock body scroll - unchanged
  const setBodyOverflow = (overflow) => {
    window.scroll(0, 0);
    document.body.style.overflow = overflow;
  };

  return (
    <div id="containerMenu" className="fixed top-0 left-0 w-full z-50">
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
      <div className="backdrop-blur-md bg-white/30">
        <div className="flex justify-between items-center px-4 mx-auto max-w-[1180px] py-4">
          <a href="/" className="flex-1 pr-5">
            <img className="max-h-[40px]" src={logo} alt="logo" />
          </a>
          <ul className="hidden md:flex gap-8 items-center">
            <li>
              <a href="/book" className={props.weight === "book" ? "font-bold" : "font-normal"}>
                WAIT TIMES
              </a>
            </li>
            <li>
              <a href="/faq" className={props.weight === "faq" ? "font-bold" : "font-normal"}>
                FAQ
              </a>
            </li>
            <li>
              <a href="/blog" className={props.weight === "blog" ? "font-bold" : "font-normal"}>
                BLOG
              </a>
            </li> 
            {/* uncomment the above to unlock the blog  */}
            <li>
              <a href="/profile" className={props.weight === "profile" ? "font-bold" : "font-normal"}>
                PROFILE
              </a>
            </li>
            {/* Conditional rendering based on authentication status */}
            {props.isLoged ? (
              <li>
                <a
                  href="/"
                  onClick={() => {
                    localStorage.setItem("accessToken", "");
                    localStorage.setItem("googleUser", "");
                  }}
                  className={`text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md`}
                >
                  LOG OUT
                </a>
              </li>
            ) : (
              <li>
                <a
                  href="/login"
                  className={`text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md`}
                >
                  LOG IN
                </a>
              </li>
            )}
          </ul>
          <div id="burger-menu-mobile-version-container">
            <BurgerMenuForMobileVersion
              isLoged={props.isLoged}
              setBodyOverflow={setBodyOverflow}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
