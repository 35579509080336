import $ from "jquery";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../Button";
import Menu from "../Menu";
import NotLoginBlock from "../NotLoginBlock";
import "../css/ProfilePageStyles.css";
import History from "../history/index.jsx";
const { LINK } = require("../../config.js");

function ProfilePage(props) {
  document.title = "Priority Scan";
  if (props.isLoged) return ProfilePageAccessed(props.isLoged);
  else return ProfilePageNotAccessed();
}

function ProfilePageNotAccessed(isLoged) {
  return <NotLoginBlock isLoged={isLoged} weight="profile"></NotLoginBlock>;
}

async function getUserData(setUser) {
  var data = {
    email: localStorage.getItem("userEmail"),
    password: localStorage.getItem("userPassword"),
    firstName: localStorage.getItem("first_name"),
    lastName: localStorage.getItem("last_name"),
    accessToken: localStorage.getItem("accessToken"),
  };
  try {
    console.log(`Sent request ${data.email}`);
    const proxyUrl = LINK || "http://16.16.201.50:81";
    const res = await fetch(`${proxyUrl}/api/users/userdata`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const answer = await res.json();
    if (res.status === 200) {
      return answer;
    } else {
      toast.warn(answer.message, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    }
  } catch (error) {
    console.error("There was an error:", error);
    return false;
  }
}

function ProfilePageAccessed(isLoged) {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    address: "",
    phoneNumber: "",
    // familyDoctorName: '',
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUserData();
      await setUser(response.userData);
    };
    const data = fetchData();
    console.log(data);
    setUser(data);
  }, []);

  const validateBirthDay = (val) => {
    const date = new Date(val);
    const today = new Date();

    if (date > today) {
      toast.warn("Invalid Birthday", {
        autoClose: 3000,
      });
      return false;
    } else if (date.getFullYear() < 1900) {
      toast.warn("Birth day can not be less than 1900-01-01", {
        autoClose: 3000,
      });
      return false;
    }
    return true;
  };

  const onChangeHadler = (field, event) => {
    if (field == "birthday") {
      if (!validateBirthDay(event.target.value)) {
        setUser({
          ...user,
          birthday: "",
        });
        return false;
      }
    }

    setUser({
      ...user,
      [field]: event.target.value,
    });
  };

  const [isChange, setChange] = useState(false);

  const disableFields = () => {
    $("#first-name-input").prop("disabled", true);
    $("#last-name-input").prop("disabled", true);
    $("#birthday-input").prop("disabled", true);
    $("#address-input").prop("disabled", true);
    $("#phone-input").prop("disabled", true);
    // $('#family-doctor-name-input').prop("disabled", true);
    $("#email-input").prop("disabled", true);
    $("#password-input").prop("disabled", true);
  };
  const enableFields = () => {
    $("#first-name-input").removeAttr("disabled");
    $("#last-name-input").removeAttr("disabled");
    $("#birthday-input").removeAttr("disabled");
    $("#address-input").removeAttr("disabled");
    $("#phone-input").removeAttr("disabled");
    // $('#family-doctor-name-input').removeAttr("disabled");
    $("#email-input").removeAttr("disabled");
    $("#password-input").removeAttr("disabled");
  };

  useEffect(() => {
    // Disable the fields when the component loads
    disableFields();
  }, []);

  return (
    <div>
      <Menu isLoged={isLoged} weight="profile"></Menu>
      <div className="pb-[30px]">
        <div id="profile-page-main-container">
          <div className="headerText">Profile</div>
          <div id="profile-page-user-data-container">
            <div className="profile-page-field-logo">First name</div>
            <input
              onLoad={() => {
                disableFields();
              }}
              id="first-name-input"
              type="text"
              className="form-control"
              value={user.firstName}
              onChange={(e) => {
                onChangeHadler("firstName", e);
              }}
            />
            <div className="profile-page-field-logo">Last name</div>
            <input
              id="last-name-input"
              type="text"
              className="form-control"
              value={user.lastName}
              onChange={(e) => {
                onChangeHadler("lastName", e);
              }}
            />
            <div className="profile-page-field-logo">Birthday</div>
            <input
              id="birthday-input"
              type="date"
              className="form-control"
              value={user.birthday}
              onChange={(e) => {
                onChangeHadler("birthday", e);
              }}
            />
            <div className="profile-page-field-logo">Address</div>
            <input
              id="address-input"
              type="text"
              className="form-control"
              value={user.address}
              onChange={(e) => {
                onChangeHadler("address", e);
              }}
            />
            <div className="profile-page-field-logo">Phone number</div>
            <PhoneInput
              country={"ca"}
              priority={{ ca: 1, us: 0 }}
              disableCountryGuess={true}
              placeholder=""
              value={user.phoneNumber}
              inputProps={{
                id: "phone-input",
              }}
              onChange={(event) => {
                setUser({
                  ...user,
                  ["phoneNumber"]: event,
                });
              }}
            />
            {/* <div className="profile-page-field-logo">Family doctor name</div>
              <input id='family-doctor-name-input' type="text" className="form-control" value={user.familyDoctorName} onChange={(e) => {onChangeHadler('familyDoctorName', e)}} /> */}
            <div className="profile-page-field-logo">Email</div>
            <input
              id="email-input"
              type="text"
              className="form-control"
              value={user.email}
              onChange={(e) => {
                onChangeHadler("email", e);
              }}
            />
            <div className="profile-page-field-logo">Password</div>
            <input
              id="password-input"
              type={showPassword ? "text" : "password"}
              className="form-control"
              value={user.password}
              onChange={(e) => {
                onChangeHadler("password", e);
              }}
            />
            <button onClick={togglePasswordVisibility}>
              {showPassword ? "Hide" : "Show"}
            </button>
            {isChange ? (
              <div id="profile-page-save-and-cancel-buttons-container">
                <Button
                  width="5em"
                  name="Save"
                  onClick={() => {
                    const proxyUrl = LINK || "http://16.16.201.50:81";
                    const id = toast.loading("Please wait...");
                    const response = fetch(
                      `${proxyUrl}/api/users/updateuserdata`,
                      {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                          firstName: $("#first-name-input").val(),
                          lastName: $("#last-name-input").val(),
                          birthday: $("#birthday-input").val(),
                          address: $("#address-input").val(),
                          phoneNumber: $("#phone-input").val(),
                          // familyDoctorName: $('#family-doctor-name-input').val(),
                          email: $("#email-input").val(),
                          password: $("#password-input").val(),
                          accessToken: localStorage.getItem("accessToken"),
                          oldEmail: localStorage.getItem("userEmail"),
                          oldPassword: localStorage.getItem("userPassword"),
                        }),
                      }
                    )
                      .then((response) => {
                        return response.json();
                      })
                      .then((data) => {
                        toast.dismiss(id);
                        if (data["message"]) {
                          toast(data["message"], {
                            position: "top-center",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          // toast.update(id, { render: , type: toast.TYPE.WARNING, isLoading: false });
                          return;
                        }
                        toast.success("Updated data!", {
                          position: "top-center",
                          autoClose: 3500,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                        disableFields();
                        setChange(false);
                      });
                  }}
                />
                {/* <Button width='5em' name="Cancel" onClick={() => {
                  disableFields();
                  setChange(false);
                }} /> */}
              </div>
            ) : (
              <div id="profile-page-save-and-cancel-buttons-container">
                <Button
                  name="Edit"
                  onClick={() => {
                    enableFields();
                    setChange(true);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {localStorage.getItem("googleUser") ? (
        <img
          src={JSON.parse(localStorage.getItem("googleUser")).picture}
          alt="google-user"
        />
      ) : (
        <div></div>
      )}

      <History />
    </div>
  );
}

export default ProfilePage;
