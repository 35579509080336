import React from "react";
// import background from './img/background.jpg';
import "./css/MainBackgroundStyles.css";
import { useNavigate } from "react-router-dom";
import { BarChart, Card, Divider, Switch } from '@tremor/react';import { useState } from 'react';
import p4MRscansData from './data/p4MRscans.json'

const chartData = p4MRscansData
    .map(site => ({
        name: site.Site, // Use the site name for each bar
        'Mean Wait Time': site.Years['2021'] ? site.Years['2021'].Mean : 0, // Access 2021 Mean Wait Time
        'Median Wait Time': site.Years['2021'] ? site.Years['2021'].Median : 0, // Access 2021 Median Wait Time
        '90th Percentile': site.Years['2021'] ? site.Years['2021']['90th Percentile'] : 0, // Access 2021 90th Percentile Wait Time
    }))
    .filter(site => site['Mean Wait Time'] >= 0.1) // Filter out sites with Mean Wait Time less than 0.1
    .sort((a, b) => a['Mean Wait Time'] - b['Mean Wait Time']); // Sort by Mean Wait Time from lowest to highest

function valueFormatter(number) {  
  const formatter = new Intl.NumberFormat('en-US', {    
    maximumFractionDigits: 0,    
    notation: 'standard',    
    style: 'unit',    
    unit: 'day',  
  });
return formatter.format(number);}

function MainBackground() {
  const history = useNavigate();
  const goToFAQ = () => {
    history("/faq");
  };

  return (
    <div id="MainBackgroundContainer">
       <div className="grid"> 
          <div>
            <Card className="sm:mx-auto max-w-[75vw]">
            <h3 className="ml-1 mr-1 pt-4 pb-4 text-2xl font-bold text-black">
                Scan Wait Times Overview
            </h3>
              <p className="text-slate-700 text-lg">Sorted from shortest to longest MEAN wait time per site</p>
              <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Explore historical <span className="font-bold underline text-black">MEAN</span> wait times for MRIs from 2021.
              </p>
              <BarChart
                data={chartData}
                index="name"
                categories={['Mean Wait Time']}
                colors={['blue']}
                valueFormatter={valueFormatter}
                yAxisWidth={145}
                showXAxis={false}
                className="mt-6 hidden h-60 sm:block"
              />
              <Divider />             
            </Card>
          </div>
          <div>
            <Card className="sm:mx-auto max-w-[75vw]">
              <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Historical <span className="font-bold underline text-black">MEDIAN</span> wait times for MRIs from 2021.
              </p>
              <BarChart
                data={chartData}
                index="name"
                categories={['Median Wait Time']}
                colors={['green']}
                valueFormatter={valueFormatter}
                yAxisWidth={145}
                showXAxis={false}
                className="mt-6 hidden h-60 sm:block"
              />
              <Divider />
                           
            </Card>
          </div>
          <div>
            <Card className="sm:mx-auto max-w-[75vw]">
              
              <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                Historical <span className="font-bold underline text-black">90th Percentile</span> wait times for MRIs from 2021.
              </p>
              <BarChart
                data={chartData}
                index="name"
                categories={['90th Percentile']}
                colors={['pink']}
                valueFormatter={valueFormatter}
                yAxisWidth={145}
                showXAxis={false}
                className="mt-6 hidden h-60 sm:block"
              />
              <Divider />
                           
            </Card>
          </div>

        </div>
      <div className="px-[20px] md:px-[30px] py-[20px] md:py-[50px] max-w-[1180px] m-auto">
        <div>
          <h2 id="BetaNotice" className="text-[36px] md:text-[48px] font-bold text-black">
           This is currently <span className="underline">FREE</span>, but please let us know how we can improve!
          </h2>
          <ol className="my-[15px]">
            <li>
              <h4 className="text-[20px] md:text-[24px] font-semibold mb-[5px]">
                Once you've found find a preferred location, print your referral
                form:
              </h4>
              <p className="text-[16px] md:text-[18px] mb-[15px]">
                Begin by printing out the referral form provided by Priority
                Scan. This document is essential—it serves as an official
                recommendation for a faster appointment at an alternative
                scanning location.
              </p>
            </li>
            <li>
              <h4 className="text-[20px] md:text-[24px] font-semibold mb-[5px]">
                Schedule an Appointment with Your Doctor:
              </h4>
              <p className="text-[16px] md:text-[18px] mb-[15px]">
                Arrange a follow-up visit with your referring physician. This
                step is crucial as it involves a professional review and
                endorsement of the new scan location you've chosen.
              </p>
            </li>
            <li>
              <h4 className="text-[20px] md:text-[24px] font-semibold mb-[5px]">
                Present Your Findings:
              </h4>
              <p className="text-[16px] md:text-[18px] mb-[15px]">
                During your appointment, professionally introduce the
                alternative scanning option discovered through Priority Scan.
                Highlight the potential for reduced wait times and the benefits
                it offers for your health journey.
              </p>
            </li>
            <li>
              <h4 className="text-[20px] md:text-[24px] font-semibold mb-[5px]">
                Provide the Referral Form:
              </h4>
              <p className="text-[16px] md:text-[18px] mb-[15px]">
                Hand over the printed referral form to your doctor. This form
                contains all the necessary details for them to efficiently
                process the change in your scan location.
              </p>
            </li>
            <li>
              <h4 className="text-[20px] md:text-[24px] font-semibold mb-[5px]">
                Request the Referral Update:
              </h4>
              <p className="text-[16px] md:text-[18px] mb-[15px]">
                Politely request your doctor to redirect your scan referral to
                the new location. This is a pivotal moment where your proactive
                approach aligns with professional healthcare processes.
              </p>
            </li>
            <li>
              <h4 className="text-[20px] md:text-[24px] font-semibold mb-[5px]">
                Await Confirmation of Your New Appointment:
              </h4>
              <p className="text-[16px] md:text-[18px] mb-[15px]">
                Once your doctor has updated the referral, expect to receive
                confirmation from the new scanning facility. Ensure that you
                confirm the appointment details as soon as you receive them.
              </p>
            </li>
          </ol>
        </div>
               
      </div>
    </div>
  );
}

export default MainBackground;
