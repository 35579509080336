import React from "react";
import { Link } from 'react-router-dom';
import Menu from "../Menu";
import Blogheader from "../BlogHeader";
import Footer from "../Footer";
import priorityScanBlog from '../data/priorityScanBlog';
import "../css/BlogPageStyles.css" // Ensure this path is correct

function BlogPage(props) {
    document.title = "Priority Scan";
  
    return (
      <div className="App">
        <Menu isLoged={props.isLoged} weight="blog" />
        <Blogheader></Blogheader>
        
        {/* Main Blog Container */}
        <div className="blog-container">
          <h2 className="blog-title">Blog Posts</h2>
          <ul>
            {priorityScanBlog.map((post) => (
              <li key={post.id} className="blog-post">
                <Link to={`/blog/${post.slug}`} className="blog-title">{post.title}</Link>
                <div className="blog-content">
                  <p>{post.excerpt}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        
        <Footer />
      </div>
    );
}

export default BlogPage;
