import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../Button";
import Header from "../Header";
import Menu from "../Menu";
import "../css/LoginPageStyles.css";
import isLoged from "../functions/IsLoged";
const { LINK } = require("../../config.js");

function LoginPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoggedIn = async () => {
      const logged = await isLoged();
      setIsLoggedIn(logged);
    };

    checkLoggedIn();
  }, []);

  async function loginRequest() {
    var data = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };
    // console.log('base');
    // console.log(`data:`);
    // console.log(data);
    try {
      console.log(`Sent request ${data.email}`, LINK);
      const proxyUrl = LINK || "http://16.16.201.50:81";
      const res = await fetch(`${proxyUrl}/api/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      // console.log(res.status);
      const answer = await res.json();
      if (res.status === 200) {
        toast.success("Logged in!", {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        let accessToken = answer.accessToken;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userEmail", data.email);
        localStorage.setItem("userPassword", data.password);
        navigate("/book");
        navigate(0);
      } else {
        toast.warn(answer.message, {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("There was an error:", error);
    }
  }

  if (!isLoggedIn) {
    return <LoginPageNotAccessed loginRequest={loginRequest} />;
  } else {
    return <LoginPageAccessed />;
  }
}

function LoginPageNotAccessed({ loginRequest }) {
  return (
    <div>
      <Menu weight="login" />
      <div id="login-page-not-logged-main-container">
        <h1 className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4 text-center">Welcome back.</h1>
        <h2 className="text-xl text-gray-600 mb-8 text-center">Let us help you find a shorter wait time.</h2>
        <div className="mb-4">
          <input
            className="input-login"
            type="email"
            placeholder="Enter your email address"
            id="email"
          />
        </div>
        <div className="mb-4">
          <input
            className="input-login"
            type="password"
            placeholder="Enter your password"
            id="password"
          />
        </div>
        <div className="text-center mb-4">
          <button
            id="login-page-login-button"
            onClick={loginRequest}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Sign in
          </button>
        </div>
        <div className="text-center text-sm">
          <span className="text-gray-500">Having trouble signing in?</span>
        </div>
        <div id="bottom-text-login-page-not-logged">
          Or <a href="/signup" className="text-blue-600 hover:text-blue-800">Sign up</a>
        </div>
      </div>
    </div>
  );
}

function LoginPageAccessed() {
  return (
    <div>
      <Menu weight="login" />
      <h1>YOU ARE LOGGED IN NOW!</h1>
    </div>
  );
}

export default LoginPage;
