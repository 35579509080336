import React, { useEffect, useState } from "react";
import { LINK } from "../../config";
import Collapsible from "react-collapsible";

const History = () => {
  const proxyUrl = LINK || "http://16.16.201.50:81";
  const [listOfApt, setListOfApt] = useState();

  useEffect(() => {
    fetch(`${proxyUrl}/api/getAllAppointment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userEmail: localStorage.getItem("userEmail") }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log("data", data);
        if (data.success && data?.listOfApt?.length) {
          setListOfApt(data?.listOfApt);
        }
      });
  }, []);

  const downloadForm = (hospital) => {
    var USER_CURRENT_APPT = JSON.parse(
      localStorage.getItem("USER_CURRENT_APPT")
    );
    const appointmentType =
      USER_CURRENT_APPT.appointmentType &&
      USER_CURRENT_APPT.appointmentType === "ct"
        ? "CT"
        : "MRI";

    const hospitalName =
      hospital
        .replace("(THE)", "")
        .replace("SITE", "")
        .replace(/[^a-z]/gi, "")
        .toLowerCase() +
      "_" +
      appointmentType +
      ".pdf";

    console.log(hospitalName);

    const link = document.createElement("a");
    link.download = hospitalName;

    // 👇️ set to relative path
    link.href = "/forms/" + hospitalName;

    link.click();
  };

  return (
    <div className="pb-5">
      {listOfApt?.length ? (
        <div className="mt-[30px] md:px-[30px] px-[20px] max-w-[1180px] m-auto">
          <h3 className="text-[24px] md:text-[28px] font-semibold">History</h3>

          {listOfApt?.map((apt, index) => (
            <div key={index}>
              <div id="BookPageForms">
                <Collapsible
                  trigger={
                    <div
                      id="userCurrentApptContainerNew2"
                      className="mt-[20px] flex justify-between items-center"
                    >
                      <div className="flex gap-x-9 flex-wrap">
                        <div id="userCurrentApptHeader">
                          Current appointment
                        </div>
                        <div>
                          <div>
                            <b>TYPE:</b>{" "}
                            {apt?.usersCurrentApt
                              ? apt?.usersCurrentApt.appointmentType.toUpperCase()
                              : ""}
                          </div>
                          <div>
                            <b>DATE:</b>{" "}
                            {apt?.usersCurrentApt
                              ? apt?.usersCurrentApt.appointmentDate?.split(
                                  "T"
                                )?.[0]
                              : ""}
                          </div>
                          <div>
                            <b>LOCATION:</b>{" "}
                            {apt?.usersCurrentApt
                              ? apt?.usersCurrentApt.livingPlace
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="arror-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="lucide lucide-chevron-down"
                        >
                          <path d="m6 9 6 6 6-6" />
                        </svg>
                      </div>
                    </div>
                  }
                >
                  {apt?.listOfCloseHospitals ? (
                    <div className="mt-[15px]">
                      {apt?.listOfCloseHospitals.map((hospital, index) => (
                        <div className="pb-[20px]">
                          <div id="newlyFoundApptContainer">
                            <div id="newlyFoundApptHeader">
                              Average wait time#{index + 1}
                            </div>
                            <div id="newlyFoundApptDescription">
                              <div id="newlyFoundDetails">
                                <div>
                                  <b>Date:</b>{" "}
                                  {
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() +
                                          hospital.medianWait
                                      )
                                    )
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </div>
                                <div
                                  style={
                                    apt?.hasPaid ? {} : { filter: "blur(10px)" }
                                  }
                                >
                                  {apt?.hasPaid ? (
                                    <span>
                                      <b>Hospital:</b> {hospital.Site}
                                    </span>
                                  ) : (
                                    "XXXXXXXXXXXXXXXXXXXXXXX"
                                  )}
                                </div>
                                <div>
                                  <b>Earlier by this many days:</b>{" "}
                                  {(
                                    (new Date(
                                      apt?.usersCurrentApt.appointmentDate
                                    ) -
                                      new Date(
                                        new Date().setDate(
                                          new Date().getDate() +
                                            hospital.medianWait
                                        )
                                      )) /
                                    (1000 * 60 * 60 * 24)
                                  ).toFixed(0)}
                                </div>
                                <div>
                                  <b>Wait days:</b> {hospital.medianWait}
                                </div>
                                <div
                                  style={
                                    apt?.hasPaid ? {} : { filter: "blur(10px)" }
                                  }
                                >
                                  {apt?.hasPaid ? (
                                    <span>
                                      <b>Distance:</b>{" "}
                                      {hospital.distance.toFixed(1)} KM
                                    </span>
                                  ) : (
                                    "XXXXXXXXXXXXXXXXXXXXX"
                                  )}
                                </div>
                              </div>
                              {apt?.hasPaid ? (
                                <div className="bg-[#efefef] w-fit mt-[5px] rounded">
                                  <button
                                    className="btn btn-default"
                                    onClick={() => downloadForm(hospital.Site)}
                                  >
                                    Download Form
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </Collapsible>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default History;
